export default function Sketch(p5) {
  let canvas
  let size = 20
  p5.setup = () => {
    // canvas = p5.createCanvas(400, 400);
    canvas = p5.createCanvas(p5.windowWidth, p5.windowHeight)
    // p5.saveCanvas(canvas, "fld.jpg");
  }

  p5.draw = () => {
    p5.background(40)
    p5.ellipse(p5.mouseX, p5.mouseY, size, size)
  }

  p5.windowResized = () => {
    p5.resizeCanvas(p5.windowWidth, p5.windowHeight)
  }
}
